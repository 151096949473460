<template>
  <div class="tile is-ancestor is-parent">
    <div
      class="tile is-vertical is-half"
      v-if="innerValue">
      <div class="tile is-parent">
        <article class="tile is-child box detail-page-tile">
          <p class="title">Rates</p>
          <div
            v-if="isNtar"
            class="field is-horizontal">
            <div class="field-body">
              <div class="field-label is-normal">
                <label class="label">Shop Rate</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <vue-numeric
                      class="input has-text-right"
                      v-model="innerValue.shopRate"
                      :min="0"
                      placeholder="Shop Rate"
                      :precision="2"
                      v-focus-inserted
                      :disabled="innerValue.readOnly || readOnlyView" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!isNtar">
            <div
              v-for="(codeType) in labourCodeTypes"
              :key="codeType.order"
              class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">{{ getLabourColumnName(codeType.code) }}</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control">
                    <vue-numeric
                      class="input has-text-right"
                      v-model="getLabourRate(codeType.code).rate"
                      :min="0"
                      :placeholder="getLabourColumnName(codeType.code)"
                      :precision="2"
                      :disabled="innerValue.readOnly || readOnlyView" />
                  </p>
                </div>
              </div>
            </div>
            <div
              v-for="(codeType) in otherLabourCodeTypes"
              :key="codeType.order"
              class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">{{ getLabourColumnName(codeType.code) }}</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control">
                    <vue-numeric
                      class="input has-text-right"
                      v-model="getLabourRate(codeType.code).rate"
                      :min="0"
                      :placeholder="getLabourColumnName(codeType.code)"
                      :precision="2"
                      :disabled="innerValue.readOnly || readOnlyView" />
                  </p>
                </div>
              </div>
            </div>
            <div
              v-if="isRacq"
              class="field is-horizontal">
              <div class="field-body">
                <div class="field-label">
                  <label class="label">RACQ Cons.</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <vue-numeric
                        class="input has-text-right"
                        v-model="innerValue.paintCons"
                        :min="0"
                        placeholder="RACQ Cons."
                        :precision="2"
                        :disabled="innerValue.readOnly || readOnlyView" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
      <div
        v-if="!isNtar"
        class="tile is-parent">
        <article class="tile is-child box detail-page-tile">
          <p class="title">Paint Rates</p>
          <div class="columns">
            <div class="column">
              <div
                v-for="(codeType, index) in paintCodeTypes.slice(0, Math.ceil(paintCodeTypes.length / 2))"
                :key="index"
                class="field is-horizontal">
                <div class="field-label is-normal">
                  <label
                    v-if="vehicle"
                    class="label"
                    :class="{ 'has-text-success' : codeType.code === vehicle.paintGroup }">{{ getLabourColumnName(codeType.code) }}</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <p class="control">
                      <vue-numeric
                        class="input has-text-right"
                        v-model="getLabourRate(codeType.code).rate"
                        :min="0"
                        :placeholder="getLabourColumnName(codeType.code)"
                        :precision="2"
                        :disabled="innerValue.readOnly || readOnlyView" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="column">
              <div
                v-for="(codeType, index) in paintCodeTypes.slice(Math.ceil(paintCodeTypes.length / 2), paintCodeTypes.length)"
                :key="index"
                class="field is-horizontal">
                <div
                  v-if="vehicle"
                  class="field-label is-normal">
                  <label
                    class="label"
                    :class="{ 'has-text-success' : codeType.code === vehicle.paintGroup }">{{ getLabourColumnName(codeType.code) }}</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <p class="control">
                      <vue-numeric
                        class="input has-text-right"
                        v-model="getLabourRate(codeType.code).rate"
                        :min="0"
                        :placeholder="getLabourColumnName(codeType.code)"
                        :precision="2"
                        :disabled="innerValue.readOnly || readOnlyView" />
                    <!-- @input="recalculatesPaintTotal(codeType.code)" /> -->
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>
    <div class="tile is-half">
      <div class="tile is-parent">
        <article class="tile is-child box detail-page-tile">
          <p class="title">Markups</p>
          <div class="field is-horizontal">
            <div class="field-body">
              <div class="field-label is-normal">
                <label class="label">New</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <vue-numeric
                      class="input has-text-right"
                      v-model="innerValue.newMarkup"
                      :min="0"
                      :max="100"
                      symbol="%"
                      placeholder="New Markup %"
                      symbol-position="suffix"
                      :precision="2"
                      :disabled="innerValue.readOnly || readOnlyView" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-body">
              <div class="field-label is-normal">
                <label class="label">Used</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <vue-numeric
                      class="input has-text-right"
                      v-model="innerValue.usedMarkup"
                      :min="0"
                      :max="100"
                      symbol="%"
                      placeholder="Used Markup %"
                      symbol-position="suffix"
                      :precision="2"
                      :disabled="innerValue.readOnly || readOnlyView" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-body">
              <div class="field-label is-normal">
                <label class="label">Recore</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <vue-numeric
                      class="input has-text-right"
                      v-model="innerValue.recoreMarkup"
                      :min="0"
                      :max="100"
                      symbol="%"
                      placeholder="Recore %"
                      symbol-position="suffix"
                      :precision="2"
                      :disabled="innerValue.readOnly || readOnlyView" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-body">
              <div class="field-label is-normal">
                <label class="label">Aftermarket</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <vue-numeric
                      class="input has-text-right"
                      v-model="innerValue.afterMarketMarkup"
                      :min="0"
                      :max="100"
                      symbol="%"
                      placeholder="Aftermarket %"
                      symbol-position="suffix"
                      :precision="2"
                      :disabled="innerValue.readOnly || readOnlyView" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-body">
              <div class="field-label is-normal">
                <label class="label">Exchange</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <vue-numeric
                      class="input has-text-right"
                      v-model="innerValue.exchangeMarkup"
                      :min="0"
                      :max="100"
                      symbol="%"
                      placeholder="Exchange %"
                      symbol-position="suffix"
                      :precision="2"
                      :disabled="innerValue.readOnly || readOnlyView" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-body">
              <div class="field-label is-normal">
                <label class="label">Reconditioned</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <vue-numeric
                      class="input has-text-right"
                      v-model="innerValue.reconditionedMarkup"
                      :min="0"
                      :max="100"
                      symbol="%"
                      placeholder="Reconditioned %"
                      symbol-position="suffix"
                      :precision="2"
                      :disabled="innerValue.readOnly || readOnlyView" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>
    <totals-floating-widget
      :active.sync="isFloatingWidgetActive"
      title="Totals"
      :rr-total="rrTotal"
      :repair-total="repairTotal"
      :rwa-total="rwaTotal"
      :paint-total="paintTotal"
      :cd-total="cdTotal"
      :crush-total="crushTotal"
      :mech-total="mechTotal"
      :fg-total="fgTotal"
      :part-total="partTotal"
      :opg-total="opgTotal"
      :total-ex-gst="innerValue.totalExGst" />
  </div>
</template>

<script>
import { LabourTimeTypes, LabourItemCodeTypes, ItemCategoryTypes, QuotingMethodTypes, ItemCodeTypes, PartTypes, EventHubTypes } from '@/enums'
import VueNumeric from '@/components/VueNumeric'
import { FocusInserted } from '@/components/directives'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import _orderBy from 'lodash/orderBy'
import QuoteService from './QuoteService'
import { TotalsFloatingWidget } from './components'
import { QuoteLabourRateModel } from '@/classes/viewmodels'
import { QuoteLabourMixin, QuoteOtherLabourMixin, QuoteOpgMixin, QuotePartMixin } from './mixins'

export default {
  name: 'QuoteRatesMarkups',
  components: {
    VueNumeric,
    TotalsFloatingWidget
  },
  directives: {
    FocusInserted
  },
  mixins: [QuoteLabourMixin, QuoteOtherLabourMixin, QuoteOpgMixin, QuotePartMixin],
  props: {
    value: null,
    vehicle: null,
    readOnlyView: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      innerValue: null,
      // vehicle: null,
      isFloatingWidgetActive: true
    }
  },
  computed: {
    labourCodeEnum: function() {
      return LabourItemCodeTypes
    },
    itemCodeTypes() {
      return ItemCodeTypes
    },
    partTypes() {
      return PartTypes
    },
    isNtar() {
      return (
        this.innerValue &&
        (this.innerValue.labourType === LabourTimeTypes.NTAR ||
          this.innerValue.labourType === LabourTimeTypes.LTAR ||
          this.innerValue.labourType === LabourTimeTypes.eMTA)
      )
    },
    isRacq() {
      return this.innerValue && this.innerValue.labourType === LabourTimeTypes.RACQ
    },
    // labourTotal() {
    //   return this.innerValue.rrTotal + this.innerValue.repairTotal + this.innerValue.paintTotal + this.innerValue.rwaTotal
    // },
    // paintTotal() {
    //   if (this.innerValue) {
    //     return this.calculateItemCategoryTotal(this.value.quotingMethod, this.innerValue.labours, ItemCategoryTypes.PAINT)
    //   }
    // },
    // rrTotal() {
    //   if (this.innerValue) {
    //     return this.calculateItemCategoryTotal(this.value.quotingMethod, this.innerValue.labours, ItemCategoryTypes.RR)
    //   }
    // },
    // repairTotal() {
    //   if (this.innerValue) {
    //     return this.calculateItemCategoryTotal(this.value.quotingMethod, this.innerValue.labours, ItemCategoryTypes.REP)
    //   }
    // },
    // rwaTotal() {
    //   if (this.innerValue) {
    //     return this.calculateItemCategoryTotal(this.value.quotingMethod, this.innerValue.labours, ItemCategoryTypes.RWA)
    //   }
    // },
    // opgTotal() {
    //   return this.calculateItemCategoryTotal(this.value.quotingMethod, this.innerValue.opgs, ItemCategoryTypes.OPG)
    // },
    // partTotal() {
    //   return this.calculateItemCategoryTotal(this.value.quotingMethod, this.innerValue.parts, ItemCategoryTypes.PART)
    // },
    // cdTotal() {
    //   if (this.innerValue) {
    //     return this.calculateItemCategoryTotal(this.value.quotingMethod, this.innerValue.others, ItemCategoryTypes.CD)
    //   }
    // },
    // crushTotal() {
    //   if (this.innerValue) {
    //     return this.calculateItemCategoryTotal(this.value.quotingMethod, this.innerValue.others, ItemCategoryTypes.CRUSH)
    //   }
    // },
    // mechTotal() {
    //   if (this.innerValue) {
    //     return this.calculateItemCategoryTotal(this.value.quotingMethod, this.innerValue.others, ItemCategoryTypes.MECH)
    //   }
    // },
    // fgTotal() {
    //   if (this.innerValue) {
    //     return this.calculateItemCategoryTotal(this.value.quotingMethod, this.innerValue.others, ItemCategoryTypes.FIBER)
    //   }
    // },
    paintCodeTypes() {
      const types = _orderBy(Object.values(ItemCodeTypes).filter(v => v.type === 'paint'), 'order')
      return types
    },
    labourCodeTypes() {
      const types = _orderBy(Object.values(ItemCodeTypes).filter(v => v.type === 'labour'), 'order')
      return types
    },
    otherLabourCodeTypes() {
      const types = _orderBy(Object.values(ItemCodeTypes).filter(v => v.type === 'otherlabour'), 'order')
      return types
    },
    rrRate() {
      const rate = this.innerValue ? this.innerValue.rates.find(r => r.labourCodeId === ItemCodeTypes.RR.code) : null
      return rate ? rate.rate : null
    },
    repairRate() {
      const rate = this.innerValue ? this.innerValue.rates.find(r => r.labourCodeId === ItemCodeTypes.REP.code) : null
      return rate ? rate.rate : null
    },
    rwaRate() {
      const rate = this.innerValue ? this.innerValue.rates.find(r => r.labourCodeId === ItemCodeTypes.RWA.code) : null
      return rate ? rate.rate : null
    },
    mechRate() {
      const rate = this.innerValue ? this.innerValue.rates.find(r => r.labourCodeId === ItemCodeTypes.MECH.code) : null
      return rate ? rate.rate : null
    },
    cdRate() {
      const rate = this.innerValue ? this.innerValue.rates.find(r => r.labourCodeId === ItemCodeTypes.CD.code) : null
      return rate ? rate.rate : null
    },
    crushRate() {
      const rate = this.innerValue ? this.innerValue.rates.find(r => r.labourCodeId === ItemCodeTypes.CRUSH.code) : null
      return rate ? rate.rate : null
    },
    fgRate() {
      const rate = this.innerValue ? this.innerValue.rates.find(r => r.labourCodeId === ItemCodeTypes.FIBER.code) : null
      return rate ? rate.rate : null
    },
    s1Rate() {
      const rate = this.innerValue ? this.innerValue.rates.find(r => r.labourCodeId === ItemCodeTypes.S1.code) : null
      return rate ? rate.rate : null
    },
    s2Rate() {
      const rate = this.innerValue ? this.innerValue.rates.find(r => r.labourCodeId === ItemCodeTypes.S2.code) : null
      return rate ? rate.rate : null
    },
    s3Rate() {
      const rate = this.innerValue ? this.innerValue.rates.find(r => r.labourCodeId === ItemCodeTypes.S3.code) : null
      return rate ? rate.rate : null
    },
    m1Rate() {
      const rate = this.innerValue ? this.innerValue.rates.find(r => r.labourCodeId === ItemCodeTypes.M1.code) : null
      return rate ? rate.rate : null
    },
    m2Rate() {
      const rate = this.innerValue ? this.innerValue.rates.find(r => r.labourCodeId === ItemCodeTypes.M2.code) : null
      return rate ? rate.rate : null
    },
    m3Rate() {
      const rate = this.innerValue ? this.innerValue.rates.find(r => r.labourCodeId === ItemCodeTypes.M3.code) : null
      return rate ? rate.rate : null
    }
  },
  watch: {
    innerValue: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    rrTotal(newVal) {
      this.innerValue.rrTotal = this.rrTotal
    },
    repairTotal(newVal) {
      this.innerValue.repairTotal = this.repairTotal
    },
    rwaTotal(newVal) {
      this.innerValue.rwaTotal = this.rwaTotal
    },
    cdTotal(newVal) {
      this.innerValue.cdTotal = this.cdTotal
    },
    crushTotal(newVal) {
      this.innerValue.crushTotal = this.crushTotal
    },
    mechTotal(newVal) {
      this.innerValue.mechTotal = this.mechTotal
    },
    fgTotal(newVal) {
      this.innerValue.fgTotal = this.fgTotal
    },
    rrRate(newVal, oldVal) {
      if (oldVal) {
        this.recalculatesLabourTotal(ItemCodeTypes.RR.code)
      }
    },
    repairRate(newVal, oldVal) {
      if (oldVal) {
        this.recalculatesLabourTotal(ItemCodeTypes.REP.code)
      }
    },
    rwaRate(newVal, oldVal) {
      if (oldVal) {
        this.recalculatesLabourTotal(ItemCodeTypes.RWA.code)
      }
    },
    mechRate(newVal, oldVal) {
      if (oldVal) {
        this.recalculatesOtherLabourTotal(ItemCodeTypes.MECH.code)
      }
    },
    cdRate(newVal, oldVal) {
      if (oldVal) {
        this.recalculatesOtherLabourTotal(ItemCodeTypes.CD.code)
      }
    },
    crushRate(newVal, oldVal) {
      if (oldVal) {
        this.recalculatesOtherLabourTotal(ItemCodeTypes.CRUSH.code)
      }
    },
    fgRate(newVal, oldVal) {
      if (oldVal) {
        this.recalculatesOtherLabourTotal(ItemCodeTypes.FIBER.code)
      }
    },
    s1Rate(newVal, oldVal) {
      if (oldVal) {
        this.recalculatesPaintTotal(ItemCodeTypes.S1.code)
      }
    },
    s2Rate(newVal, oldVal) {
      if (oldVal) {
        this.recalculatesPaintTotal(ItemCodeTypes.S2.code)
      }
    },
    s3Rate(newVal, oldVal) {
      if (oldVal) {
        this.recalculatesPaintTotal(ItemCodeTypes.S3.code)
      }
    },
    m1Rate(newVal, oldVal) {
      if (oldVal) {
        this.recalculatesPaintTotal(ItemCodeTypes.M1.code)
      }
    },
    m2Rate(newVal, oldVal) {
      if (oldVal) {
        this.recalculatesPaintTotal(ItemCodeTypes.M2.code)
      }
    },
    m3Rate(newVal, oldVal) {
      if (oldVal) {
        this.recalculatesPaintTotal(ItemCodeTypes.M3.code)
      }
    },
    'innerValue.newMarkup': function(newVal, oldVal) {
      if (oldVal) {
        this.recalculatesPartTotal(PartTypes.NEW, oldVal)
      }
    },
    'innerValue.usedMarkup': function(newVal, oldVal) {
      if (oldVal) {
        this.recalculatesPartTotal(PartTypes.USED, oldVal)
      }
    },
    'innerValue.recordMarkup': function(newVal, oldVal) {
      if (oldVal) {
        this.recalculatesPartTotal(PartTypes.RECR, oldVal)
      }
    },
    'innerValue.afterMarketMarkup': function(newVal, oldVal) {
      if (oldVal) {
        this.recalculatesPartTotal(PartTypes.AFT, oldVal)
      }
    },
    'innerValue.exchangeMarkup': function(newVal, oldVal) {
      if (oldVal) {
        this.recalculatesPartTotal(PartTypes.EXCH, oldVal)
      }
    },
    'innerValue.reconditionedMarkup': function(newVal, oldVal) {
      if (oldVal) {
        this.recalculatesPartTotal(PartTypes.RECD, oldVal)
      }
    },
    'innerValue.shopRate': function(newVal, oldVal) {
      if (oldVal && this.innerValue.rates && this.isNtar) {
        this.innerValue.rates.forEach(function(item, index) {
          item.rate = newVal
        })
        this.recalculatesOtherLabourTotal()
        this.recalculatesLabourTotal()
        this.recalculatesOpgTotal()
        this.recalculatesPaintTotal(this.vehicle.paintGroup)
      }
    }
  },
  created() {
    this.innerValue = this.value
  },
  mounted() {
    this.$eventHub.$on(EventHubTypes.EntitySaved, () => {
      this.innerValue = this.value
    })
  },
  beforeDestroy() {
    this.$eventHub.$off(EventHubTypes.EntitySaved)
  },
  methods: {
    getLabourRate(code) {
      if (!this.innerValue.rates) {
        return {}
      }
      var rate = this.innerValue.rates.find(i => i.labourCodeId === code)
      if (!rate) {
        const labourType = this.$labourTypes.find(t => t.labourTypeCode === code) || this.$labourTypes.find(t => t.labourTypeCode === 'PAINT')
        console.log(code, labourType)
        let newRate = new QuoteLabourRateModel(this.innerValue.quoteId, code, labourType.labourTypeId, this.isNtar ? this.innerValue.shopRate : 0)
        // const newRate = {
        //   quoteId: this.innerValue.quoteId,
        //   labourCodeId: code,
        //   labourTypeId: code,
        //   rate: this.isNtar ? this.innerValue.shopRate : 0,
        //   modifiedBy: '',
        //   modifiedDate: null,
        //   createdBy: '',
        //   createdDate: null,
        //   isNew: true,
        //   quoteVersion: 0,
        //   deleted: false
        // }
        this.innerValue.rates.push(newRate)
        rate = newRate
      }
      return rate
    },
    getLabourColumnName(code) {
      if (!this.innerValue.rates) {
        return ''
      }
      var rate = this.innerValue.rates.find(i => i.labourCodeId === code)
      // console.log(rate)
      return rate ? rate.labourCodeId : code
    },
    async getVehicleEntity() {
      this.vehicle = await QuoteService.getVehicleEntity(this.innerValue.vehicleId)
    },
    getRate(code) {
      const rate = !this.isNtar ? this.innerValue.rates.find(i => i.labourCodeId === code).rate : this.innerValue.shopRate
      return rate
    },
    recalculatesPaintTotal(code) {
      if (this.innerValue && this.vehicle && code === this.vehicle.paintGroup) {
        console.log('recalculatesPaintTotal')
        const items = this.innerValue.labours.filter(i => !i.deleted && i.itemType === ItemCategoryTypes.PAINT)
        const rate = this.getRate(code)
        const vm = this
        items.forEach(function(item) {
          item.rate = item.rev !== '1' ? rate : item.rate
          if (vm.innerValue.quotingMethod === QuotingMethodTypes.Hour) {
            item.dollarValue = roundAwayFromZero(item.rate * item.hourValue)
          } else {
            item.dollarValue = item.hourValue
          }
        })
        this.innerValue.paintTotal = this.paintTotal
        this.isFloatingWidgetActive = true
      }
      this.recalculatesOpgTotal(code)
    },
    recalculatesOpgTotal(code) {
      if (this.innerValue) {
        console.log('recalculatesOpgTotal')
        const items = code
          ? this.innerValue.opgs.filter(i => !i.deleted && i.itemType === ItemCategoryTypes.OPG && i.opgCode === code)
          : this.innerValue.opgs.filter(i => !i.deleted && i.itemType === ItemCategoryTypes.OPG)
        const rate = this.getRate(code)
        const vm = this
        items.forEach(function(item) {
          item.rate = item.rev !== '1' ? rate : item.rate
          if (vm.innerValue.quotingMethod === QuotingMethodTypes.Hour) {
            item.dollarValue = roundAwayFromZero(item.rate * item.hourValue)
          } else {
            item.dollarValue = item.hourValue
          }
        })
        this.innerValue.opgTotal = this.opgTotal
        this.isFloatingWidgetActive = true
      }
    },
    recalculatesLabourTotal(code) {
      if (this.innerValue) {
        console.log('recalculatesLabourTotal')
        const items = code ? this.innerValue.labours.filter(i => !i.deleted && i.itemType === code) : this.innerValue.labours.filter(i => !i.deleted)
        const rate = this.getRate(code)
        const vm = this
        items.forEach(function(item) {
          item.rate = item.rev !== '1' ? rate : item.rate
          if (vm.innerValue.quotingMethod === QuotingMethodTypes.Hour) {
            item.dollarValue = roundAwayFromZero(item.rate * item.hourValue)
          } else {
            item.dollarValue = item.hourValue
          }
        })
        this.isFloatingWidgetActive = true
      }
    },
    recalculatesOtherLabourTotal(code) {
      if (this.innerValue) {
        console.log('recalculatesOtherLabourTotal')
        const items = code ? this.innerValue.others.filter(i => !i.deleted && i.itemType === code) : this.innerValue.others.filter(i => !i.deleted)
        const rate = this.getRate(code)
        const vm = this
        items.forEach(function(item) {
          item.rate = item.rev !== '1' ? rate : item.rate
          if (vm.innerValue.quotingMethod === QuotingMethodTypes.Hour) {
            item.dollarValue = roundAwayFromZero(item.rate * item.hourValue)
          } else {
            item.dollarValue = item.hourValue
          }
        })
        this.isFloatingWidgetActive = true
      }
    },
    recalculatesPartTotal(partType, oldMarkupPercent) {
      // debounce because if statement is checking reactive properties "item.markupPercent"
      if (this.innerValue) {
        console.log('recalculatesPartTotal')
        const items = this.innerValue.parts.filter(i => !i.deleted && i.mark === partType.value)
        let markup = 0
        switch (partType.value) {
          case PartTypes.NEW.value:
            markup = this.innerValue.newMarkup
            break
          case PartTypes.USED.value:
            markup = this.innerValue.usedMarkup
            break
          case PartTypes.EXCH.value:
            markup = this.innerValue.exchangeMarkup
            break
          case PartTypes.RECR.value:
            markup = this.innerValue.recoreMarkup
            break
          case PartTypes.RECD.value:
            markup = this.innerValue.reconditionedMarkup
            break
          case PartTypes.AFT.value:
            markup = this.innerValue.afterMarketMarkup
            break
        }
        // console.log(markup)
        items.forEach(function(item) {
          if (item.markupPercent === 0 || item.markupPercent === oldMarkupPercent) {
            item.markupPercent = markup
            item.markupValue = roundAwayFromZero(item.value * (1 + markup / 100))
          }
        })
        this.innerValue.partTotal = this.partTotal
        this.isFloatingWidgetActive = true
      }
    }
  }
}
</script>
